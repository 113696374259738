<template>
  <div class="container g-pb-30 col-xl-2">
    <div class="text-center">
      <a
        class="button tp-caption NotGeneric-CallToAction rev-btn rs-parallaxlevel-0"
        style="
          background-color: #e74c3c;
          z-index: 9;
          font-weight: bold;
          white-space: nowrap;
          outline: none;
          box-shadow: none;
          box-sizing: border-box;
          text-transform: uppercase;
          border-width: 2px;
        "
        href="/apply-form"
      >
        <a style="color: white">Apply Now</a>
      </a>
    </div>
  </div>
</template>
