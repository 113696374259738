<template>
  <!-- Section Content -->
  <section id="contact" class="container-fluid g-color-white">
    <div class="row">
      <div class="col-lg-6 d-flex align-items-center g-theme-bg-color-1 g-py-50 g-pa-15 g-pa-30--md">
        <div class="w-100">
          <div class="row">
            <div class="col-md-6 col-lg-12 g-mb-60 g-mb-0--md g-mb-60--lg">
              <div class="u-heading-v2-2--bottom g-brd-primary g-mb-30">
                <h2
                  class="text-uppercase u-heading-v2__title g-font-weight-800 g-font-size-30 g-font-size-40--md g-color-white mb-0"
                >
                  Get in touch
                </h2>
              </div>
              <p class="g-color-gray-light-v4 mb-0">
                CASE has an established reputation and level of trust with the client, so contact us to discuss how we
                can continue to build a productive relationship and deliver impeccable results for our clients.
              </p>
            </div>

            <div class="col-md-6 col-lg-12 d-md-flex d-lg-block align-self-center">
              <address class="text-uppercase g-font-size-12 mb-0">
                <div class="g-pos-rel g-pl-35 g-mb-25">
                  <i class="fa fa-map-marker g-absolute-centered--y g-left-0 g-font-size-20 g-color-primary fa-fw"></i>
                  <strong>LEESBURG, VA</strong>
                </div>

                <div class="g-pos-rel g-pl-35 g-mb-25">
                  <i class="fa fa-envelope g-absolute-centered--y g-left-0 g-font-size-20 g-color-primary fa-fw"></i>
                  <a class="g-color-white" href="mailto:info@consultwithcase.com">
                    <strong>info@consultwithcase.com</strong>
                  </a>
                </div>

                <div class="g-pos-rel g-pl-35 g-mb-25">
                  <i class="fab fa-github g-absolute-centered--y g-left-0 g-font-size-20 g-color-primary fa-fw"></i>
                  <a class="g-color-white" href="https://github.com/caseconsulting" target="_blank" rel="noopener">
                    <strong>case consulting</strong>
                  </a>
                </div>

                <div class="g-pos-rel g-pl-35 g-mb-25">
                  <i class="fab fa-twitter g-absolute-centered--y g-left-0 g-font-size-20 g-color-primary fa-fw"></i>
                  <a class="g-color-white" href="https://twitter.com/consultwithcase" target="_blank" rel="noopener">
                    <strong>@ConsultWithCase</strong>
                  </a>
                </div>

                <div class="g-pos-rel g-pl-35 g-mb-25">
                  <i class="fab fa-youtube g-absolute-centered--y g-left-0 g-font-size-20 g-color-primary fa-fw"></i>
                  <a
                    class="g-color-white"
                    href="https://www.youtube.com/channel/UC_oJY4OrOpLNrIBAN7Y-9fA"
                    target="_blank"
                    rel="noopener"
                  >
                    <strong>Case Consulting</strong>
                  </a>
                </div>

                <div class="g-pos-rel g-pl-35 g-mb-25">
                  <i
                    class="fab fa-facebook-f red-icon g-absolute-centered--y g-left-0 g-font-size-20 g-color-primary fa-fw"
                  ></i>
                  <a
                    class="g-color-white"
                    href="https://www.facebook.com/ConsultwithCase/"
                    target="_blank"
                    rel="noopener"
                  >
                    <strong>ConsultwithCase</strong>
                  </a>
                </div>

                <div class="g-pos-rel g-pl-35">
                  <i
                    class="fab fa-linkedin-in g-absolute-centered--y g-left-0 g-font-size-20 g-color-primary fa-fw"
                  ></i>
                  <a
                    class="g-color-white"
                    href="https://www.linkedin.com/company/2558032/"
                    target="_blank"
                    rel="noopener"
                  >
                    <strong>Case Consulting LLC</strong>
                  </a>
                </div>
              </address>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 px-0 contact-section-map" id="map"></div>
    </div>
  </section>
  <!-- End Section Content -->
</template>
